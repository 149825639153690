<template>
  <div style="margin: 0 auto; max-width: 500px;padding: 10px;min-width: 500px;">
    <h3 style="text-align: center;">{{ $t('All.批号补PASS单') }}</h3>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="auto"
      label-position="right">
      <el-form-item :label="$t('All.批次号')" prop="Order_Number">
        <el-input v-model="formData.Order_Number" @blur="Geterpitem(formData.Order_Number)" :placeholder="$t('All.请输入采购单')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.品名')">
        <el-input v-model="formData.Product_name" :placeholder="$t('All.请输入品名')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.规格')">
        <el-input v-model="formData.Pddsc2" :placeholder="$t('All.请输入规格')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.料号')">
        <el-input v-model="formData.Item_No" :placeholder="$t('All.请输入料号')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.生产日期')">
        <el-date-picker v-model="formData.Date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :style="{width: '100%'}" :placeholder="$t('All.请选择生产日期')" clearable></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.生产数量')" prop="Warehousing">
        <el-input v-model="formData.Warehousing" :placeholder="$t('All.请输入生产数量')" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in Boxlist"
        :label="$t('All.数量')"
        :key="index"
      >
        <el-input v-model="domain.Boxquantity" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" style="width:100px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.张数')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')">
          <el-select :style="{width: '100%'}" v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item style="margin: 0 auto;text-align: center;" size="large">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      formData: {
        Date: '',
        Time: new Date(),
        Order_Number: '',
        Item_No: '',
        Product_name: '',
        Supplier: '',
        Pddsc2: '',
        Warehousing: '',
        Box: 0,
        Boxquantity: 0,
        Count: 0,
        Numbers: '',
        Operator: '',
        User: this.$store.state.Login.Username,
        Userzh: this.$store.state.Login.User,
        Type: 3,
        Month: '',
        Region: '',
        State: 0,
        Company: '',
        Order_no: '',
        Serialcode: '',
        cid: 0
      },
      rules: {
        Product_name: [{
          required: true,
          message: this.$t('All.请输入品名'),
          trigger: 'blur'
        }],
        Gg: [{
          required: true,
          message: this.$t('All.请输入规格'),
          trigger: 'blur'
        }],
        Item_no: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Lot: [{
          required: true,
          message: this.$t('All.请输入Lot号'),
          trigger: 'blur'
        }],
        Order_Number: [{
          required: true,
          message: this.$t('All.请输入采购单'),
          trigger: 'blur'
        }],
        Jj: [{
          required: true,
          message: this.$t('All.请输入绞距'),
          trigger: 'blur'
        }],
        Jt: [{
          required: true,
          message: this.$t('All.请输入机台'),
          trigger: 'blur'
        }],
        Bb: [{
          required: true,
          message: this.$t('All.请输入班别'),
          trigger: 'blur'
        }],
        Boxquantity: [{
          required: true,
          message: this.$t('All.请输入生产数量'),
          trigger: 'blur'
        }],
        Cdate: [{
          required: true,
          message: this.$t('All.请选择生产日期'),
          trigger: 'change'
        }]
      },
      Printname: '',
      Boxlist: [
        {
          Box: 1,
          Boxquantity: 1
        }
      ],
      dialogVisible: false
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    addDomainbut () {
      this.Boxlist.push({
        Box: 1,
        Boxquantity: 1
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.Boxlist.indexOf(item)
          if (index !== -1) {
            this.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    submitForm () {
      const loadingInstance = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        if (this.Printname.length < 1) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.请选择打印机后在进行打印'))
        }
        this.$refs.elForm.validate(async valid => {
          if (!valid) {
            loadingInstance.close()
            return this.$message.error(this.$t('All.数据填写不完整'))
          }
          var isbool = 0
          this.Boxlist.forEach(element => {
            isbool += parseInt(element.Box)
          })
          if (isbool > 10) {
            loadingInstance.close()
            this.$confirm(`${this.$t('All.确认要打印')}${isbool}${this.$t('All.张')}？`, this.$t('All.提示'), {
              confirmButtonText: this.$t('All.确认'),
              cancelButtonText: this.$t('All.取消'),
              type: 'warning'
            })
              .then(async () => {
                const loadingInstance1 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
                const postdata = {
                  Boxlist: this.Boxlist,
                  Traceprin: this.formData,
                  PrinterData: this.Printname,
                  UserData: this.$store.state.Login
                }
                const { data: res } = await this.$http.post('/api/Semifinished/BPASSSeleUserData', postdata)
                if (res.status !== 200) {
                  loadingInstance1.close()
                  return this.$message.error(res.msg)
                }
                loadingInstance1.close()
                this.$message.success(res.msg)
                this.$refs.elForm.resetFields()
              })
              .catch(() => {
              })
            return
          }
          const loadingInstance2 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
          const postdata = {
            Boxlist: this.Boxlist,
            Traceprin: this.formData,
            PrinterData: this.Printname,
            UserData: this.$store.state.Login
          }
          const { data: res } = await this.$http.post('/api/Semifinished/BPASSSeleUserData', postdata)
          if (res.status !== 200) {
            loadingInstance2.close()
            return this.$message.error(res.msg)
          }
          loadingInstance2.close()
          this.$message.success(res.msg)
          this.$refs.elForm.resetFields()
        })
      } catch (error) {
        loadingInstance.close()
      }
    },
    async Geterpitem (row) {
      try {
        if (row.length === 0) return
        const { data: res } = await this.$http.get(`/api/Semifinished/Getiqcpass?Pch=${row}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.formData = res.response
      } catch (error) {
        return this.$message.error(error)
      }
    }
  }
}

</script>
<style>
</style>
